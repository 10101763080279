import { format, isValid } from 'date-fns';
import { get, maxBy, minBy } from 'lodash';
import {
  numberWithCommas,
  replacePatternWithValues,
} from '../../../components/PageBuilder/helper';

export default class HelperLib {
  constructor(sessionData) {
    this.sessionData = sessionData;
  }

  callFunction(stringFunction) {
    try {
      const [, functionName, stringParams = ''] =
        stringFunction.match(/(\w+)\(([^)]*)\)/);

      if (this[functionName]) {
        const functionParams = stringParams.split(',').map((param) => {
          const inputString = param.trim();
          if (inputString === 'true' || inputString === 'false') {
            return inputString === 'true';
          }

          if (
            !Number.isNaN(inputString) &&
            !Number.isNaN(parseFloat(inputString))
          ) {
            return parseFloat(inputString);
          }

          return inputString;
        });

        if (functionParams.length) {
          const sessionValuePath = functionParams.shift();
          const sessionValue = this.getStringSessionValue(sessionValuePath);

          if (sessionValuePath.includes('[].')) {
            return this[functionName](sessionValuePath, ...functionParams);
          }

          if (sessionValue) {
            return this[functionName](sessionValue, ...functionParams);
          }
        }
      }

      return '';
    } catch (e) {
      return '';
    }
  }

  getSessionValue(path) {
    return get(this.sessionData, path);
  }

  getStringSessionValue(path) {
    const sessionValue = this.getSessionValue(path);
    return sessionValue ? String(sessionValue) : '';
  }

  phoneFormat(value) {
    return replacePatternWithValues(value, '(999)999-9999)');
  }

  currencyFormat(value) {
    return `$${numberWithCommas(value)}`;
  }

  accountNumberFormat(value) {
    return `XXXXXXXXXXXX${value.slice(-4)}`;
  }

  residenceTypeFormat(value) {
    const types = {
      O: 'Own',
      R: 'Rent',
      OT: 'Other',
    };
    return types?.[value] || value;
  }

  substringFormat(value, indexStart, indexEnd) {
    if (value) {
      return String(value).substring(indexStart, indexEnd);
    }
  }

  dateFormat(value, dateFormat = 'MM/dd/yyyy') {
    try {
      if (value) {
        const date = new Date(value);
        if (!isValid(date)) {
          return value;
        }

        return format(date, dateFormat);
      }
    } catch (e) {
      return value;
    }
  }

  arrMaxBy(path = '') {
    try {
      const [collectionPath, propertyPath] = String(path).split('[].');
      const collection = this.getSessionValue(collectionPath);

      if (propertyPath && collection?.length) {
        const maxItem = maxBy(collection, propertyPath);

        if(!maxItem){
          return '';
        }
        return get(maxItem, propertyPath, '');
      }
    } catch (e) {
      return '';
    }
  }

  arrMinBy(path = '') {
    try {
      const [collectionPath, propertyPath] = String(path).split('[].');
      const collection = this.getSessionValue(collectionPath);

      if (propertyPath && collection?.length) {
        const minItem = minBy(collection, propertyPath);

        if(!minItem){
          return '';
        }
        return get(minItem, propertyPath, '');
      }
    } catch (e) {
      return '';
    }
  }

  arrFirstNonEmptyBy(path = '') {
    try {
      const [collectionPath, propertyPath] = String(path).split('[].');
      const collection = this.getSessionValue(collectionPath);

      if (propertyPath && collection?.length) {
        const itemValueBy = collection.find((item) => !!get(item, propertyPath));

        if(!itemValueBy){
          return '';
        }
        return get(itemValueBy, propertyPath, '');
      }
    } catch (e) {
      return '';
    }
  }
}
