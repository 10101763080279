import { useContext } from 'react';
import { DataContext } from '../../../components/layout/Default';
import Helper from './helper';

const useSessionValueReplace = () => {
  const { sessionData } = useContext(DataContext);
  const helper = new Helper(sessionData);

  const replaceSessionValues = (str = '') => {
    if (typeof str !== 'string') {
      return str;
    }

    return str.replace(/{([^}]+)}/g, (match, value) => {
      if (match && value) {
        if (/(\w+)\(([^)]*)\)/.test(value)) {
          return helper.callFunction(value);
        }

        return helper.getStringSessionValue(value);
      }

      return match;
    });
  };

  return {
    replaceSessionValues,
  };
};

export default useSessionValueReplace;
