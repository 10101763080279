import { useField, useFormikContext } from 'formik';
import axios from 'axios';
import { get } from 'lodash';
import { useContext, useState } from 'react';
import { DataContext } from '../../../components/layout/Default';

const useAjaxValidation = (component) => {
  const [field, fieldMeta, fieldHelpers] = useField(
    component.value || component.field.value,
  );
  const formik = useFormikContext();
  const { sessionData } = useContext(DataContext);
  const [hasError, setHasError] = useState(null);

  const doRequest = async (data, errorMessage) => {
    try {
      const response = await axios.post('/api/decision-ajax-validation', data);
      if (response.data?.status === 'success') {
        fieldHelpers.setError(null);
        setHasError(null);
        return response.data;
      }
      fieldHelpers.setError(errorMessage);
      setHasError(errorMessage);
    } catch (e) {
      fieldHelpers.setError(errorMessage);
      setHasError(errorMessage);
    }
  };

  const setOutputFieldValues = (data, errorMessage, outputFields = []) => {
    for (const outputField of outputFields) {
      const { value: inputFullName } = outputField.field;
      const decisionValue = get(data, inputFullName, '');

      formik.setFieldValue(inputFullName, decisionValue || '', false);
    }
  };

  const zipValidation = async (errorMessage) => {
    if (
      field?.value &&
      /^[0-9]{5}$/.test(field?.value) &&
      component.field?.inputField
    ) {
      const { ajax } = component.field.inputField;

      if (ajax) {
        const ajaxResponse = await doRequest(
          {
            ajaxName: 'zipcode',
            customersession: {
              apZip: field.value,
            },
          },
          errorMessage,
        );

        if (ajax?.outputField?.length > 0) {
          // eslint-disable-next-line no-restricted-syntax
          for (const outputField of ajax.outputField) {
            const { value: inputFullName } = outputField.field;
            const inputPropertyName = inputFullName.split('.').at(-1);
            const formikValue = get(formik.values, inputFullName);
            if (inputPropertyName.toLowerCase().indexOf('city') >= 0) {
              const decisionCity = get(
                ajaxResponse,
                'customersession.apCity',
                '',
              );
              if (decisionCity !== formikValue) {
                formik.setFieldValue(inputFullName, decisionCity, false);
              }
            }

            if (inputPropertyName.toLowerCase().indexOf('state') >= 0) {
              const decisionState = get(
                ajaxResponse,
                'customersession.apState',
                '',
              );
              if (decisionState !== formikValue) {
                formik.setFieldValue(inputFullName, decisionState, false);
              }
            }
          }
        }
      }
    }
  };

  const progressiveAbaValidation = async (errorMessage) => {
    if (
      field?.value &&
      /^[0-9]{9}$/.test(field?.value) &&
      component.field?.inputField
    ) {
      const { ajax } = component.field.inputField;

      if (ajax) {
        const ajaxResponse = await doRequest(
          {
            ajaxName: 'progressive_aba',
            customersession: {
              storeID: get(sessionData, 'store.ID'),
              lenderID: get(sessionData, 'application.lenderID'),
              apBankRoutingNumber: field.value,
            },
          },
          errorMessage,
        );

        if (ajax?.outputField?.length > 0) {
          setOutputFieldValues(ajaxResponse, errorMessage, ajax.outputField);
        }
      }
    }
  };

  const ajaxValidation = async () => {
    if (
      component.field?.inputField?.ajax &&
      component.field.validation?.length
    ) {
      const AJAX_VALIDATION_MAP = {
        zip: zipValidation,
        progressive_aba: progressiveAbaValidation,
      };
      for (const {
        val_key: validation,
        val_message: validationMessage,
      } of component.field.validation) {
        if (AJAX_VALIDATION_MAP[validation]) {
          setHasError(null);
          fieldHelpers.setError(null);
          // eslint-disable-next-line no-await-in-loop
          await AJAX_VALIDATION_MAP[validation](validationMessage);
          if (hasError) {
            break;
          }
        }
      }
    }
  };

  return { ajaxValidation, hasError };
};

export default useAjaxValidation;
