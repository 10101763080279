import * as Yup from 'yup';
import {
  addMonths,
  differenceInDays,
  differenceInYears,
  getDate,
  getDay, isAfter,
  isEqual,
  isFuture,
  isValid,
  subYears,
} from 'date-fns';
import { get } from 'lodash';
import { valAddressReplace } from './validationHelper';
import { isNumeric } from '../../helper';

const mixed = Yup.mixed().nullable();

const string = Yup.string().nullable();

const number = (message = 'Invalid value') =>
  Yup.mixed()
    .nullable()
    .test('number-validation', message, (val) => {
      if (val) {
        return /^[0-9]+$/.test(val);
      }
      return true;
    });

const digits = (message = 'Invalid value') =>
  Yup.string()
    .nullable()
    .test('digits-validation', message, (val) => {
      if (val) {
        return /^[0-9]+$/.test(val);
      }
      return true;
    });

const alpha = (message = 'Invalid value') =>
  Yup.string()
    .nullable()
    .test('alpha-validation', message, (val) => {
      if (val) {
        return /^[a-zA-Z]*$/.test(val);
      }
      return true;
    });

const alphanum = (message = 'Invalid value') =>
  Yup.string()
    .nullable()
    .test('alphanum-validation', message, (val) => {
      if (val) {
        return /^[a-zA-Z0-9]*$/.test(val);
      }
      return true;
    });

const currency = (message = 'Invalid value') =>
  Yup.mixed()
    .nullable()
    .test('currency-validation', message, (val) => {
      if (val) {
        return /^\d+(?:\.\d{1,2})?$/.test(val);
      }
      return true;
    });
const email = (message = 'Invalid value') =>
  Yup.string()
    .nullable()
    .email('Invalid value')
    .test('no-reply', message, (val) => {
      if (val) {
        return !/^(NOEMAIL|NOREPLY)@(?:[A-Z0-9-]{2,}\.)+[A-Z]{2,}$/i.test(val);
      }
      return true;
    });

const phone = (message = 'Invalid value') =>
  Yup.string(message)
    .nullable()
    .matches(/^\d{10}$/, { message, excludeEmptyString: true })
    .test('invalid-phone', message, (val) => {
      if (val) {
        const invalidFormats = [
          /^((\(0|0)|(\(1|1))[0-9].*$/,
          /^(0{10}|1{10}|2{10}|3{10}|4{10}|5{10}|6{10}|7{10}|8{10}|9{10}|911[0-9]{7})$/,
        ];

        return !invalidFormats.some((rx) => rx.test(val));
      }
      return true;
    });

const ssn = (message = 'Invalid value') =>
  Yup.string()
    .nullable()
    .test('invalid-ssn', message, (val) => {
      if (val) {
        const invalidFormats = [
          /^123456789|012345678|987654321|876543210|078051120|219099999$/,
          /^(0{9}|1{9}|2{9}|3{9}|4{9}|5{9}|6{9}|7{9}|8{9}|9{9})$/,
        ];

        return !invalidFormats.some((rx) => rx.test(val));
      }
      return true;
    })
    .test('valid-ssn', message, (val) => {
      if (val) {
        if (
          /^(000000001|000110000|\d{3}\d{2}0000)$/.test(val) ||
          /^(9\d{2})(7[0-9]|8[0-8]|9[0-2]|9[4-9])(\d{4})$/.test(val)
        ) {
          return true;
        }
        return /^(?!000|9\d{2})\d{3}(?!00)\d{2}(?!0{4})\d{4}$/.test(val);
      }
      return true;
    });

const ssnNoItin = (message = 'Invalid value') =>
  Yup.string()
    .nullable()
    .test('invalid-ssn', message, (val) => {
      if (val) {
        const invalidFormats = [
          /^123456789|012345678|987654321|876543210|078051120|219099999$/,
          /^(0{9}|1{9}|2{9}|3{9}|4{9}|5{9}|6{9}|7{9}|8{9}|9{9})$/,
        ];

        return !invalidFormats.some((rx) => rx.test(val));
      }
      return true;
    })
    .test('valid-ssn', message, (val) => {
      if (val) {
        if (/^(000000001|000110000|\d{3}\d{2}0000)$/.test(val)) {
          return true;
        }
        return /^(?!000|9\d{2})\d{3}(?!00)\d{2}(?!0{4})\d{4}$/.test(val);
      }
      return true;
    });

const firstName = (message) =>
  Yup.string()
    .nullable()
    .test('invalid-first-name', message, (val) => {
      if (val) {
        return /^[A-Z][A-Z\s]*[A-Z\s]$/i.test(val);
      }
      return true;
    });

const lastName = (message) =>
  Yup.string()
    .nullable()
    .test('invalid-last-name', message, (val) => {
      if (val) {
        return /^[A-Z][A-Z\-\s]*[A-Z\s]$/i.test(val);
      }
      return true;
    });

const address1 = (message, fieldHelpers) =>
  Yup.string()
    .nullable()
    .test('invalid-address1', message, (val) => {
      if (val) {
        let newVal = val;
        const reg1 =
          /^(.)*(?=(.*\d){1})(?=(.*\s){1})(?=.*[a-zA-Z])[0-9a-zA-Z].{1,}/i;
        const reg2 = /^[-_.#\w\s]*$/i;
        const reg3 = // eslint-disable-next-line max-len
          /\b((P(ost|ostal)?[ \.]*(O|0)(ffice)?([ \.]*B(\.|ox)?)?)|(P(ost|ostal)?([ \.]*(O|0)(ffice)?)?[ \.]*B(\.|ox)?))\b/i;

        if (
          newVal &&
          reg1.test(newVal) &&
          reg2.test(newVal) &&
          !reg3.test(newVal)
        ) {
          newVal = newVal.replace(/([0-9])([a-zA-Z])/, '$1 $2');
          newVal = newVal.replace(/1 ST /, '1ST ');
          newVal = newVal.replace(/2 ND /, '2ND ');
          newVal = newVal.replace(/3 RD /, '3RD ');
          newVal = newVal.replace(/([0-9]) TH /, '$1TH ');
          newVal = valAddressReplace(newVal);

          if (newVal !== val) {
            fieldHelpers.setValue(newVal);
          }

          const words = newVal.split(/\s+/).length;

          if (parseInt(words, 10) >= 2) {
            return true;
          }
        }

        return false;
      }
      return true;
    });

const address2 = (message, fieldHelpers) =>
  Yup.string()
    .nullable()
    .test('invalid-address2', message, (val) => {
      if (val) {
        let newVal = val;
        const reg1 = /^[A-Z0-9\s\/\#][A-Z0-9\s\/\.\#\-\_]*/i;
        const reg2 = /^[-_.#\w\s]*$/i;
        const regSpace = /\s/;
        const regDigit = /[0-9]/;

        if (reg1.test(newVal) && reg2.test(newVal)) {
          if (!regSpace.test(newVal) && newVal?.length < 6) {
            if (!regDigit.test(newVal)) {
              newVal = `UNIT ${newVal}`;
            } else {
              newVal = `APT ${newVal}`;
            }
          }

          if (newVal !== val) {
            fieldHelpers.setValue(newVal);
          }

          const words = newVal.split(/\s+/).length;
          if (parseInt(words, 10) >= 2) {
            return true;
          }
        }

        return false;
      }
      return true;
    });

const poBox = (message, fieldHelpers) =>
  Yup.string()
    .nullable()
    .test('invalid-poBox', message, (val) => {
      if (val) {
        let newVal = val;
        const reg1 =
          /^(.)*(?=(.*\d){1})(?=(.*\s){1})(?=.*[a-zA-Z])[0-9a-zA-Z].{1,}/i;
        const reg2 = /^[-_.#\w\s]*$/i;

        if (reg1.test(newVal) && reg2.test(newVal)) {
          newVal = newVal.replace(/([0-9])([a-zA-Z])/, '$1 $2');
          newVal = newVal.replace(/1 ST /, '1ST ');
          newVal = newVal.replace(/2 ND /, '2ND ');
          newVal = newVal.replace(/3 RD /, '3RD ');
          newVal = newVal.replace(/([0-9]) TH /, '$1TH ');
          newVal = valAddressReplace(newVal);

          if (newVal !== val) {
            fieldHelpers.setValue(newVal);
          }

          const words = newVal.split(/\s+/).length;

          if (parseInt(words, 10) >= 2) {
            return true;
          }
        }

        return false;
      }
      return true;
    });

const employer = (message) =>
  Yup.string()
    .nullable()
    .test('invalid-employer', message, (val) => {
      const reg1 =
        /^[A-Z0-9][A-Z0-9\s\-&#,!~@_+;*'.?]*[A-Z0-9\-\&#,!~@_+;*'.?]$/i;
      const noMatchValues = ['none', 'ninguno', 'aucun'];
      if (!noMatchValues.includes(val)) {
        return reg1.test(val);
      }
      return false;
    });

const employerClean = (message) =>
  Yup.string()
    .nullable()
    .test('invalid-employer-clean', message, (val) => {
      if (val) {
        const reg1 = /^[A-Z0-9][A-Z0-9\s\-]*[A-Z0-9]$/i;
        const noMatchValues = ['none', 'ninguno', 'aucun'];
        if (!noMatchValues.includes(val)) {
          return reg1.test(val);
        }
        return false;
      }
      return true;
    });

const bankName = (message) =>
  Yup.string()
    .nullable()
    .test('invalid-bank-name', message, (val) => {
      if (val) {
        return /^[\w\-\s]+$/.test(val);
      }
      return true;
    });

const description = (message) =>
  Yup.string()
    .nullable()
    .test('invalid-description-name', message, (val) => {
      if (val) {
        return /^[A-Z0-9][A-Z0-9\s\-&#,!~@_+;*'.?]*[A-Z0-9\-\&#,!~@_+;*'.?]$/i.test(
          val,
        );
      }
      return true;
    });

const descriptionClean = (message) =>
  Yup.string()
    .nullable()
    .test('invalid-description-clean', message, (val) => {
      if (val) {
        return /^[A-Z0-9][A-Z0-9\s\-]*[A-Z0-9]$/i.test(val);
      }
      return true;
    });

const idNumber = (message) =>
  Yup.string()
    .nullable()
    .test('invalid-id-number', message, (val) => {
      if (val) {
        const reg1 = /^[0-9]|([0-9]+[a-zA-Z]+|[a-zA-Z]+[0-9]+)[0-9a-zA-Z]*$/;
        const reg2 = /^[a-zA-Z0-9]*$/;
        if (val?.length >= 6) {
          return reg1.test(val) && reg2.test(val);
        }
        return false;
      }
      return true;
    });

const zip = (message) =>
  Yup.string()
    .nullable()
    .test('invalid-zip', message, (val) => {
      if (val) {
        return /^[0-9]{5}$/.test(val);
      }
      return true;
    });

const canadaZip = (message) =>
  Yup.string()
    .nullable()
    .test('invalid-canada-zip', message, (val) => {
      if (val) {
        return /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i.test(
          val,
        );
      }
      return true;
    });

const minLength = (message, fieldHelpers, { param: limit }) =>
  Yup.string().nullable().min(limit, message);

const maxLength = (message, fieldHelpers, { param: limit }) =>
  Yup.string().nullable().max(limit, message);

const minValue = (message, fieldHelpers, { param: limit }) =>
  Yup.mixed().test('invalid-min-value', message, (val) => {
    if (isNumeric(val) && isNumeric(limit)) {
      const number = parseFloat(val);
      if (number >= 0) {
        return number >= parseFloat(limit);
      }
    }
    return true;
  });

const maxValue = (message, fieldHelpers, { param: limit }) =>
  Yup.mixed()
    .nullable()
    .test('invalid-max-value', message, (val) => {
      if (isNumeric(val) && isNumeric(limit)) {
        const number = parseFloat(val);
        if (number >= 0) {
          return number <= parseFloat(limit);
        }
      }
      return true;
    });

const past = (message) =>
  Yup.string()
    .nullable()
    .test('invalid-past-date', message, (val) => {
      if (val) {
        const date = new Date(val);
        return date && isValid(date) && !isFuture(date);
      }
      return true;
    });

const future = (message) =>
  Yup.string()
    .nullable()
    .test('invalid-future-date', message, (val) => {
      if (val) {
        const date = new Date(val);
        return date && isValid(date) && isFuture(date);
      }
      return true;
    });

const legalStateAge = (message, fieldHelpers, args) =>
  Yup.string()
    .nullable()
    .test('invalid-state-age', message, (val) => {
      if (val) {
        const date = new Date(val);
        if (date && isValid(date) && past(date)) {
          const { formik } = args;
          if (formik?.values?.customersession?.apState) {
            const now = new Date();
            const legalStateAges = {
              AR: 19,
              WI: 15,
            };
            const apStateAge = legalStateAges?.[formik.values.apState] || 18;

            return differenceInYears(now, date) >= apStateAge;
          }
        }
      }
      return true;
    });

const snapNextPay = (message, fieldHelpers, args) =>
  Yup.string()
    .nullable()
    .test('invalid-snap-next-pay-date', message, (val) => {
      if (val) {
        const date = new Date(val);
        if (date && isValid(date) && future(date)) {
          const customersession = get(
            args,
            'formik.values.customersession',
            {},
          );
          if (customersession) {
            const { apIncomeFrequency, apLastPayDate } = customersession;
            const apLastPayDateObj = new Date(apLastPayDate);
            if (apIncomeFrequency && apLastPayDate) {
              if (apIncomeFrequency === 'weekly') {
                return differenceInDays(date, apLastPayDateObj) === 7;
              }

              if (apIncomeFrequency === 'bi-weekly') {
                return differenceInDays(date, apLastPayDateObj) === 14;
              }

              if (apIncomeFrequency === 'semi-monthly') {
                const range1Check = (num) => num >= 1 && num <= 15;
                const range2Check = (num) => num >= 15 && num <= 31;

                const lastPayDateDayNumber = getDate(apLastPayDateObj);
                const nextPayDateDayNumber = getDate(date);

                if (differenceInDays(date, apLastPayDateObj) < 7) {
                  return false;
                }

                if (range1Check(lastPayDateDayNumber)) {
                  return range2Check(nextPayDateDayNumber);
                }

                if (range2Check(lastPayDateDayNumber)) {
                  return range1Check(nextPayDateDayNumber);
                }

                return differenceInDays(date, apLastPayDateObj) >= 7;
              }
              if (apIncomeFrequency === 'monthly') {
                const validNextPayDate = addMonths(apLastPayDateObj, 1);
                return isEqual(date, validNextPayDate);
              }

              if (apIncomeFrequency === 'monthly_week') {
                const daysDiff = differenceInDays(date, apLastPayDateObj);
                const lastPayDateWeekDay = getDay(apLastPayDateObj);
                const nextPayDateWeekDay = getDay(date);
                return (
                  daysDiff >= 20 &&
                  daysDiff <= 35 &&
                  lastPayDateWeekDay === nextPayDateWeekDay
                );
              }
            }
          }
        }
      }
      return true;
    });

const pastYear = (message) =>
  Yup.string()
    .nullable()
    .test('invalid-past-year-date', message, (val) => {
      if (val) {
        const date = new Date(val);
        const oneYearAgo = subYears(new Date(), 1);
        return date && isValid(date) && !isFuture(date) && isAfter(date, oneYearAgo);
      }
      return true;
    });

export default {
  mixed,
  string,
  number,
  digits,
  alpha,
  alphanum,
  email,
  phone,
  currency,
  ssn,
  ssnNoItin,
  firstName,
  lastName,
  address1,
  address2,
  poBox,
  employer,
  employerClean,
  bankName,
  description,
  descriptionClean,
  idNumber,
  zip,
  canadaZip,
  minLength,
  maxLength,
  minValue,
  maxValue,
  past,
  future,
  legalStateAge,
  snapNextPay,
  pastYear,
};
